import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/SEO"
import "../scss/main.scss"

export default () => {
    return (
      <Layout>
        <Seo />
        <h1>
          404 Oops! You go nowhere!!
        </h1>
        <main className="btn-wrapper">
            <Link to="/" >
              <button className="btn">
              Go Home!
              </button>
            </Link>
        </main>
      </Layout>
    )
}